.dropzone {
  margin: 0;
  padding: 9vh;
  font-size: 3em;
  text-align: center;
  font-family: Arial !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 5px dotted black;
}